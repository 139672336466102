import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Trans, useTranslation } from 'react-i18next';
import { useWizard } from '@shared/ui/misc/Wizard';
import { ContainerColumn, ContainerRow } from '@components/styled';
import { Body, BodyBold, BodyMedium, TextEmphasizedMedium, } from '@components/styled/Typography';
import Icon from '@shared/ui/icons/Icon';
import { ReactComponent as AlertBadgeIcon } from '@icons/custom/confirmation.svg';
import SuccessIcon from '@shared/ui/icons/SuccessIcon';
import Button from '@shared/ui/buttons/Button';
const Container = styled(ContainerColumn)((props) => ({
    width: 'auto',
    height: '100%',
    justifyContent: 'space-between',
    gap: props.theme.spacing_sizes.l,
    maxWidth: 420,
}));
const Title = styled(TextEmphasizedMedium)(() => ({
    letterSpacing: '-0.456px',
}));
const WarningMessageContainer = styled(ContainerColumn)((props) => ({
    width: 'auto',
    height: 'auto',
    padding: props.theme.spacing_sizes.xm,
    gap: props.theme.spacing_sizes.m,
    borderRadius: props.theme.shape.borderRadius,
    backgroundColor: '#F8F9FA',
}));
const WarningMessage = styled(ContainerColumn)(() => ({
    width: 'auto',
    height: 'auto',
}));
const InfoContainer = styled(ContainerColumn)((props) => ({
    width: 'auto',
    height: 'auto',
    gap: props.theme.spacing_sizes.l,
}));
const InfoItem = styled(ContainerRow)((props) => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'start',
    gap: props.theme.spacing_sizes.m,
}));
const Content = styled(ContainerColumn)((props) => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.l,
}));
const ButtonsContainer = styled(ContainerColumn)((props) => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.m,
}));
const Initial = ({ onCancel, }) => {
    const { goNext } = useWizard();
    const { t } = useTranslation();
    return (_jsxs(Container, { children: [_jsx(Title, { children: Trans({
                    i18nKey: 'settings.sidebars.delete_account.title',
                    ns: 'translation',
                }) }), _jsxs(Content, { children: [_jsx(Icon, { size: 200, IconComponent: AlertBadgeIcon, keepOriginalColor: true }), _jsx(WarningMessageContainer, { children: _jsxs(WarningMessage, { children: [_jsx(BodyBold, { children: t('settings.sidebars.delete_account.warning_label') }), _jsx(Body, { children: t('settings.sidebars.delete_account.warning_text') })] }) }), _jsxs(InfoContainer, { children: [_jsxs(InfoItem, { children: [_jsx(SuccessIcon, { size: 18 }), _jsx(BodyMedium, { children: t('settings.sidebars.delete_account.informational_points.first') })] }), _jsxs(InfoItem, { children: [_jsx(SuccessIcon, { size: 18 }), _jsx(BodyMedium, { children: t('settings.sidebars.delete_account.informational_points.second') })] }), _jsxs(InfoItem, { children: [_jsx(SuccessIcon, { size: 18 }), _jsx(BodyMedium, { children: t('settings.sidebars.delete_account.informational_points.third') })] })] })] }), _jsxs(ButtonsContainer, { children: [_jsx(Button, { onClick: goNext, fullWidth: true, children: t('settings.sidebars.delete_account.actions.confirmation') }), _jsx(Button, { variant: 'tinted', onClick: onCancel, fullWidth: true, children: t('actions.cancel', { ns: 'common' }) })] })] }));
};
export default Initial;
